import {bootstrapApplication} from '@angular/platform-browser';
import {appConfig} from './app/app.config';
import {AppComponent} from './app/app.component';
import {handlers} from "./mocks/handlers";
import {setupWorker} from "msw/browser";
import {isDevMode} from "@angular/core";

async function prepareApp() {
  if (isDevMode()) {
    const worker = setupWorker(...handlers);
    return worker.start({
      onUnhandledRequest: 'bypass',
    });
  }
  return Promise.resolve();
}

prepareApp()
  .then(
    () => bootstrapApplication(AppComponent, appConfig)
  ).catch((err) => console.error(err));
