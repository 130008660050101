import { toPublicUrl } from './toPublicUrl.mjs';
import { InternalError, devUtils } from '../internal/devUtils.mjs';
async function onUnhandledRequest(request, strategy = "warn") {
  const url = new URL(request.url);
  const publicUrl = toPublicUrl(url) + url.search;
  const unhandledRequestMessage = `intercepted a request without a matching request handler:

  \u2022 ${request.method} ${publicUrl}

If you still wish to intercept this unhandled request, please create a request handler for it.
Read more: https://mswjs.io/docs/getting-started/mocks`;
  function applyStrategy(strategy2) {
    switch (strategy2) {
      case "error":
        {
          devUtils.error("Error: %s", unhandledRequestMessage);
          throw new InternalError(devUtils.formatMessage('Cannot bypass a request when using the "error" strategy for the "onUnhandledRequest" option.'));
        }
      case "warn":
        {
          devUtils.warn("Warning: %s", unhandledRequestMessage);
          break;
        }
      case "bypass":
        break;
      default:
        throw new InternalError(devUtils.formatMessage('Failed to react to an unhandled request: unknown strategy "%s". Please provide one of the supported strategies ("bypass", "warn", "error") or a custom callback function as the value of the "onUnhandledRequest" option.', strategy2));
    }
  }
  if (typeof strategy === "function") {
    strategy(request, {
      warning: applyStrategy.bind(null, "warn"),
      error: applyStrategy.bind(null, "error")
    });
    return;
  }
  if (url.protocol === "file:") {
    return;
  }
  applyStrategy(strategy);
}
export { onUnhandledRequest };
