import {http, HttpResponse} from "msw";


export const handlers = [
  http.get('/api/board/1', () => {
    return HttpResponse.json({
      id: '1',
      title: 'Sales Navi Meeting（仮）について',
      contents: [
        {id: '1', contentType: 'heading', headingType: 'h1', text: 'コンセプト'},
        {id: '2', contentType: 'embed', title: '', width: '100%', height: 600, src: 'https://miro.com/app/embed/uXjVK8cjkG4=/?pres=1&frameId=3458764592200307016&embedId=929041754869" frameborder="0" scrolling="no" allow="fullscreen; clipboard-read; clipboard-write'},
        {id: '3', contentType: 'heading', headingType: 'h1', text: '機能一覧'},
        {id: '4', contentType: 'heading', headingType: 'h2', text: '会議体'},
        {id: '5', contentType: 'text', text: '会議体を作成し、会議体毎に会議を自動生成することができる'},
        {id: '6', contentType: 'heading', headingType: 'h2', text: 'データ埋め込み'},
        {id: '7', contentType: 'text', text: 'Excel（Spreadsheet）、PowerPoint（Google Slide）、その他BI（Tableau、PowerBI）といったデータを埋め込み表示することができる'},
        {id: '8', contentType: 'heading', headingType: 'h2', text: 'コメント'},
        {id: '9', contentType: 'text', text: '会議のアジェンダ内にコメントすることができ、会議中の会話を記録することができる'},
        {id: '11', contentType: 'heading', headingType: 'h2', text: 'ノート'},
        {id: '12', contentType: 'text', text: '会議毎にノートを書くことができ、議事メモを残すことができる'},
        {id: '13', contentType: 'heading', headingType: 'h2', text: 'タスク'},
        {id: '14', contentType: 'text', text: '会議体ごとにタスクを管理することができる'},
      ]
    })
  }),
]
