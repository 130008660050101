// node_modules/.pnpm/outvariant@1.4.2/node_modules/outvariant/lib/index.mjs
var POSITIONALS_EXP = /(%?)(%([sdijo]))/g;
function serializePositional(positional, flag) {
  switch (flag) {
    case "s":
      return positional;
    case "d":
    case "i":
      return Number(positional);
    case "j":
      return JSON.stringify(positional);
    case "o":
      {
        if (typeof positional === "string") {
          return positional;
        }
        const json = JSON.stringify(positional);
        if (json === "{}" || json === "[]" || /^\[object .+?\]$/.test(json)) {
          return positional;
        }
        return json;
      }
  }
}
function format(message, ...positionals) {
  if (positionals.length === 0) {
    return message;
  }
  let positionalIndex = 0;
  let formattedMessage = message.replace(POSITIONALS_EXP, (match, isEscaped, _, flag) => {
    const positional = positionals[positionalIndex];
    const value = serializePositional(positional, flag);
    if (!isEscaped) {
      positionalIndex++;
      return value;
    }
    return match;
  });
  if (positionalIndex < positionals.length) {
    formattedMessage += ` ${positionals.slice(positionalIndex).join(" ")}`;
  }
  formattedMessage = formattedMessage.replace(/%{2,2}/g, "%");
  return formattedMessage;
}
var STACK_FRAMES_TO_IGNORE = 2;
function cleanErrorStack(error2) {
  if (!error2.stack) {
    return;
  }
  const nextStack = error2.stack.split("\n");
  nextStack.splice(1, STACK_FRAMES_TO_IGNORE);
  error2.stack = nextStack.join("\n");
}
var InvariantError = class extends Error {
  constructor(message, ...positionals) {
    super(message);
    this.message = message;
    this.name = "Invariant Violation";
    this.message = format(message, ...positionals);
    cleanErrorStack(this);
  }
};
var invariant = (predicate, message, ...positionals) => {
  if (!predicate) {
    throw new InvariantError(message, ...positionals);
  }
};
invariant.as = (ErrorConstructor, predicate, message, ...positionals) => {
  if (!predicate) {
    const formatMessage = positionals.length === 0 ? message : format(message, positionals);
    let error2;
    try {
      error2 = Reflect.construct(ErrorConstructor, [formatMessage]);
    } catch (err) {
      error2 = ErrorConstructor(formatMessage);
    }
    throw error2;
  }
};

// node_modules/.pnpm/is-node-process@1.2.0/node_modules/is-node-process/lib/index.mjs
function isNodeProcess() {
  if (typeof navigator !== "undefined" && navigator.product === "ReactNative") {
    return true;
  }
  if (typeof process !== "undefined") {
    const type = process.type;
    if (type === "renderer" || type === "worker") {
      return false;
    }
    return !!(process.versions && process.versions.node);
  }
  return false;
}

// src/browser/setupWorker/start/createStartHandler.ts
import { devUtils as devUtils7 } from '../core/utils/internal/devUtils.mjs';

// node_modules/.pnpm/@open-draft+until@2.1.0/node_modules/@open-draft/until/lib/index.mjs
var until = async promise => {
  try {
    const data = await promise().catch(error2 => {
      throw error2;
    });
    return {
      error: null,
      data
    };
  } catch (error2) {
    return {
      error: error2,
      data: null
    };
  }
};

// src/browser/setupWorker/start/utils/getWorkerInstance.ts
import { devUtils } from '../core/utils/internal/devUtils.mjs';

// src/browser/utils/getAbsoluteWorkerUrl.ts
function getAbsoluteWorkerUrl(workerUrl) {
  return new URL(workerUrl, location.href).href;
}

// src/browser/setupWorker/start/utils/getWorkerByRegistration.ts
function getWorkerByRegistration(registration, absoluteWorkerUrl, findWorker) {
  const allStates = [registration.active, registration.installing, registration.waiting];
  const relevantStates = allStates.filter(state => {
    return state != null;
  });
  const worker = relevantStates.find(worker2 => {
    return findWorker(worker2.scriptURL, absoluteWorkerUrl);
  });
  return worker || null;
}

// src/browser/setupWorker/start/utils/getWorkerInstance.ts
var getWorkerInstance = async (url, options = {}, findWorker) => {
  const absoluteWorkerUrl = getAbsoluteWorkerUrl(url);
  const mockRegistrations = await navigator.serviceWorker.getRegistrations().then(registrations => registrations.filter(registration => getWorkerByRegistration(registration, absoluteWorkerUrl, findWorker)));
  if (!navigator.serviceWorker.controller && mockRegistrations.length > 0) {
    location.reload();
  }
  const [existingRegistration] = mockRegistrations;
  if (existingRegistration) {
    return existingRegistration.update().then(() => {
      return [getWorkerByRegistration(existingRegistration, absoluteWorkerUrl, findWorker), existingRegistration];
    });
  }
  const registrationResult = await until(async () => {
    const registration = await navigator.serviceWorker.register(url, options);
    return [
    // Compare existing worker registration by its worker URL,
    // to prevent irrelevant workers to resolve here (such as Codesandbox worker).
    getWorkerByRegistration(registration, absoluteWorkerUrl, findWorker), registration];
  });
  if (registrationResult.error) {
    const isWorkerMissing = registrationResult.error.message.includes("(404)");
    if (isWorkerMissing) {
      const scopeUrl = new URL(options?.scope || "/", location.href);
      throw new Error(devUtils.formatMessage(`Failed to register a Service Worker for scope ('${scopeUrl.href}') with script ('${absoluteWorkerUrl}'): Service Worker script does not exist at the given path.

Did you forget to run "npx msw init <PUBLIC_DIR>"?

Learn more about creating the Service Worker script: https://mswjs.io/docs/cli/init`));
    }
    throw new Error(devUtils.formatMessage("Failed to register the Service Worker:\n\n%s", registrationResult.error.message));
  }
  return registrationResult.data;
};

// src/browser/setupWorker/start/utils/enableMocking.ts
import { devUtils as devUtils3 } from '../core/utils/internal/devUtils.mjs';

// src/browser/setupWorker/start/utils/printStartMessage.ts
import { devUtils as devUtils2 } from '../core/utils/internal/devUtils.mjs';
function printStartMessage(args = {}) {
  if (args.quiet) {
    return;
  }
  const message = args.message || "Mocking enabled.";
  console.groupCollapsed(`%c${devUtils2.formatMessage(message)}`, "color:orangered;font-weight:bold;");
  console.log("%cDocumentation: %chttps://mswjs.io/docs", "font-weight:bold", "font-weight:normal");
  console.log("Found an issue? https://github.com/mswjs/msw/issues");
  if (args.workerUrl) {
    console.log("Worker script URL:", args.workerUrl);
  }
  if (args.workerScope) {
    console.log("Worker scope:", args.workerScope);
  }
  console.groupEnd();
}

// src/browser/setupWorker/start/utils/enableMocking.ts
async function enableMocking(context, options) {
  context.workerChannel.send("MOCK_ACTIVATE");
  await context.events.once("MOCKING_ENABLED");
  if (context.isMockingEnabled) {
    devUtils3.warn(`Found a redundant "worker.start()" call. Note that starting the worker while mocking is already enabled will have no effect. Consider removing this "worker.start()" call.`);
    return;
  }
  context.isMockingEnabled = true;
  printStartMessage({
    quiet: options.quiet,
    workerScope: context.registration?.scope,
    workerUrl: context.worker?.scriptURL
  });
}

// src/browser/setupWorker/start/utils/createMessageChannel.ts
var WorkerChannel = class {
  constructor(port) {
    this.port = port;
  }
  postMessage(event, ...rest) {
    const [data, transfer] = rest;
    this.port.postMessage({
      type: event,
      data
    }, {
      transfer
    });
  }
};

// src/browser/utils/pruneGetRequestBody.ts
function pruneGetRequestBody(request) {
  if (["HEAD", "GET"].includes(request.method)) {
    return void 0;
  }
  return request.body;
}

// src/browser/utils/parseWorkerRequest.ts
function parseWorkerRequest(incomingRequest) {
  return new Request(incomingRequest.url, {
    ...incomingRequest,
    body: pruneGetRequestBody(incomingRequest)
  });
}

// src/browser/setupWorker/start/createRequestListener.ts
import { RequestHandler } from '../core/handlers/RequestHandler.mjs';
import { handleRequest } from '../core/utils/handleRequest.mjs';
import { devUtils as devUtils4 } from '../core/utils/internal/devUtils.mjs';
import { toResponseInit } from '../core/utils/toResponseInit.mjs';
var createRequestListener = (context, options) => {
  return async (event, message) => {
    const messageChannel = new WorkerChannel(event.ports[0]);
    const requestId = message.payload.id;
    const request = parseWorkerRequest(message.payload);
    const requestCloneForLogs = request.clone();
    const requestClone = request.clone();
    RequestHandler.cache.set(request, requestClone);
    context.requests.set(requestId, requestClone);
    try {
      await handleRequest(request, requestId, context.getRequestHandlers(), options, context.emitter, {
        onPassthroughResponse() {
          messageChannel.postMessage("PASSTHROUGH");
        },
        async onMockedResponse(response, {
          handler,
          parsedResult
        }) {
          const responseClone = response.clone();
          const responseCloneForLogs = response.clone();
          const responseInit = toResponseInit(response);
          if (context.supports.readableStreamTransfer) {
            const responseStreamOrNull = response.body;
            messageChannel.postMessage("MOCK_RESPONSE", {
              ...responseInit,
              body: responseStreamOrNull
            }, responseStreamOrNull ? [responseStreamOrNull] : void 0);
          } else {
            const responseBufferOrNull = response.body === null ? null : await responseClone.arrayBuffer();
            messageChannel.postMessage("MOCK_RESPONSE", {
              ...responseInit,
              body: responseBufferOrNull
            });
          }
          if (!options.quiet) {
            context.emitter.once("response:mocked", () => {
              handler.log({
                request: requestCloneForLogs,
                response: responseCloneForLogs,
                parsedResult
              });
            });
          }
        }
      });
    } catch (error2) {
      if (error2 instanceof Error) {
        devUtils4.error(`Uncaught exception in the request handler for "%s %s":

%s

This exception has been gracefully handled as a 500 response, however, it's strongly recommended to resolve this error, as it indicates a mistake in your code. If you wish to mock an error response, please see this guide: https://mswjs.io/docs/recipes/mocking-error-responses`, request.method, request.url, error2.stack ?? error2);
        messageChannel.postMessage("MOCK_RESPONSE", {
          status: 500,
          statusText: "Request Handler Error",
          headers: {
            "Content-Type": "application/json"
          },
          body: JSON.stringify({
            name: error2.name,
            message: error2.message,
            stack: error2.stack
          })
        });
      }
    }
  };
};

// src/browser/utils/checkWorkerIntegrity.ts
import { devUtils as devUtils5 } from '../core/utils/internal/devUtils.mjs';
async function checkWorkerIntegrity(context) {
  context.workerChannel.send("INTEGRITY_CHECK_REQUEST");
  const {
    payload
  } = await context.events.once("INTEGRITY_CHECK_RESPONSE");
  if (payload.checksum !== "26357c79639bfa20d64c0efca2a87423") {
    devUtils5.warn(`The currently registered Service Worker has been generated by a different version of MSW (${payload.packageVersion}) and may not be fully compatible with the installed version.

It's recommended you update your worker script by running this command:

  \u2022 npx msw init <PUBLIC_DIR>

You can also automate this process and make the worker script update automatically upon the library installations. Read more: https://mswjs.io/docs/cli/init.`);
  }
}

// node_modules/.pnpm/@mswjs+interceptors@0.29.0/node_modules/@mswjs/interceptors/lib/browser/chunk-6HYIRFX2.mjs
var encoder = new TextEncoder();
function encodeBuffer(text) {
  return encoder.encode(text);
}
function decodeBuffer(buffer, encoding) {
  const decoder = new TextDecoder(encoding);
  return decoder.decode(buffer);
}
function toArrayBuffer(array) {
  return array.buffer.slice(array.byteOffset, array.byteOffset + array.byteLength);
}

// node_modules/.pnpm/@mswjs+interceptors@0.29.0/node_modules/@mswjs/interceptors/lib/browser/chunk-OMISYKWR.mjs
var IS_PATCHED_MODULE = Symbol("isPatchedModule");
function isPropertyAccessible(obj, key) {
  try {
    obj[key];
    return true;
  } catch (e) {
    return false;
  }
}
var RESPONSE_STATUS_CODES_WITHOUT_BODY = /* @__PURE__ */new Set([101, 103, 204, 205, 304]);
function isResponseWithoutBody(status) {
  return RESPONSE_STATUS_CODES_WITHOUT_BODY.has(status);
}
function createServerErrorResponse(body) {
  return new Response(JSON.stringify(body instanceof Error ? {
    name: body.name,
    message: body.message,
    stack: body.stack
  } : body), {
    status: 500,
    statusText: "Unhandled Exception",
    headers: {
      "Content-Type": "application/json"
    }
  });
}
function isResponseError(response) {
  return isPropertyAccessible(response, "type") && response.type === "error";
}

// node_modules/.pnpm/@open-draft+logger@0.3.0/node_modules/@open-draft/logger/lib/index.mjs
var __defProp = Object.defineProperty;
var __export = (target, all) => {
  for (var name in all) __defProp(target, name, {
    get: all[name],
    enumerable: true
  });
};
var colors_exports = {};
__export(colors_exports, {
  blue: () => blue,
  gray: () => gray,
  green: () => green,
  red: () => red,
  yellow: () => yellow
});
function yellow(text) {
  return `\x1B[33m${text}\x1B[0m`;
}
function blue(text) {
  return `\x1B[34m${text}\x1B[0m`;
}
function gray(text) {
  return `\x1B[90m${text}\x1B[0m`;
}
function red(text) {
  return `\x1B[31m${text}\x1B[0m`;
}
function green(text) {
  return `\x1B[32m${text}\x1B[0m`;
}
var IS_NODE = isNodeProcess();
var Logger = class {
  constructor(name) {
    this.name = name;
    this.prefix = `[${this.name}]`;
    const LOGGER_NAME = getVariable("DEBUG");
    const LOGGER_LEVEL = getVariable("LOG_LEVEL");
    const isLoggingEnabled = LOGGER_NAME === "1" || LOGGER_NAME === "true" || typeof LOGGER_NAME !== "undefined" && this.name.startsWith(LOGGER_NAME);
    if (isLoggingEnabled) {
      this.debug = isDefinedAndNotEquals(LOGGER_LEVEL, "debug") ? noop : this.debug;
      this.info = isDefinedAndNotEquals(LOGGER_LEVEL, "info") ? noop : this.info;
      this.success = isDefinedAndNotEquals(LOGGER_LEVEL, "success") ? noop : this.success;
      this.warning = isDefinedAndNotEquals(LOGGER_LEVEL, "warning") ? noop : this.warning;
      this.error = isDefinedAndNotEquals(LOGGER_LEVEL, "error") ? noop : this.error;
    } else {
      this.info = noop;
      this.success = noop;
      this.warning = noop;
      this.error = noop;
      this.only = noop;
    }
  }
  prefix;
  extend(domain) {
    return new Logger(`${this.name}:${domain}`);
  }
  /**
   * Print a debug message.
   * @example
   * logger.debug('no duplicates found, creating a document...')
   */
  debug(message, ...positionals) {
    this.logEntry({
      level: "debug",
      message: gray(message),
      positionals,
      prefix: this.prefix,
      colors: {
        prefix: "gray"
      }
    });
  }
  /**
   * Print an info message.
   * @example
   * logger.info('start parsing...')
   */
  info(message, ...positionals) {
    this.logEntry({
      level: "info",
      message,
      positionals,
      prefix: this.prefix,
      colors: {
        prefix: "blue"
      }
    });
    const performance2 = new PerformanceEntry();
    return (message2, ...positionals2) => {
      performance2.measure();
      this.logEntry({
        level: "info",
        message: `${message2} ${gray(`${performance2.deltaTime}ms`)}`,
        positionals: positionals2,
        prefix: this.prefix,
        colors: {
          prefix: "blue"
        }
      });
    };
  }
  /**
   * Print a success message.
   * @example
   * logger.success('successfully created document')
   */
  success(message, ...positionals) {
    this.logEntry({
      level: "info",
      message,
      positionals,
      prefix: `\u2714 ${this.prefix}`,
      colors: {
        timestamp: "green",
        prefix: "green"
      }
    });
  }
  /**
   * Print a warning.
   * @example
   * logger.warning('found legacy document format')
   */
  warning(message, ...positionals) {
    this.logEntry({
      level: "warning",
      message,
      positionals,
      prefix: `\u26A0 ${this.prefix}`,
      colors: {
        timestamp: "yellow",
        prefix: "yellow"
      }
    });
  }
  /**
   * Print an error message.
   * @example
   * logger.error('something went wrong')
   */
  error(message, ...positionals) {
    this.logEntry({
      level: "error",
      message,
      positionals,
      prefix: `\u2716 ${this.prefix}`,
      colors: {
        timestamp: "red",
        prefix: "red"
      }
    });
  }
  /**
   * Execute the given callback only when the logging is enabled.
   * This is skipped in its entirety and has no runtime cost otherwise.
   * This executes regardless of the log level.
   * @example
   * logger.only(() => {
   *   logger.info('additional info')
   * })
   */
  only(callback) {
    callback();
  }
  createEntry(level, message) {
    return {
      timestamp: /* @__PURE__ */new Date(),
      level,
      message
    };
  }
  logEntry(args) {
    const {
      level,
      message,
      prefix,
      colors: customColors,
      positionals = []
    } = args;
    const entry = this.createEntry(level, message);
    const timestampColor = customColors?.timestamp || "gray";
    const prefixColor = customColors?.prefix || "gray";
    const colorize = {
      timestamp: colors_exports[timestampColor],
      prefix: colors_exports[prefixColor]
    };
    const write = this.getWriter(level);
    write([colorize.timestamp(this.formatTimestamp(entry.timestamp))].concat(prefix != null ? colorize.prefix(prefix) : []).concat(serializeInput(message)).join(" "), ...positionals.map(serializeInput));
  }
  formatTimestamp(timestamp) {
    return `${timestamp.toLocaleTimeString("en-GB")}:${timestamp.getMilliseconds()}`;
  }
  getWriter(level) {
    switch (level) {
      case "debug":
      case "success":
      case "info":
        {
          return log;
        }
      case "warning":
        {
          return warn;
        }
      case "error":
        {
          return error;
        }
    }
  }
};
var PerformanceEntry = class {
  startTime;
  endTime;
  deltaTime;
  constructor() {
    this.startTime = performance.now();
  }
  measure() {
    this.endTime = performance.now();
    const deltaTime = this.endTime - this.startTime;
    this.deltaTime = deltaTime.toFixed(2);
  }
};
var noop = () => void 0;
function log(message, ...positionals) {
  if (IS_NODE) {
    process.stdout.write(format(message, ...positionals) + "\n");
    return;
  }
  console.log(message, ...positionals);
}
function warn(message, ...positionals) {
  if (IS_NODE) {
    process.stderr.write(format(message, ...positionals) + "\n");
    return;
  }
  console.warn(message, ...positionals);
}
function error(message, ...positionals) {
  if (IS_NODE) {
    process.stderr.write(format(message, ...positionals) + "\n");
    return;
  }
  console.error(message, ...positionals);
}
function getVariable(variableName) {
  if (IS_NODE) {
    return process.env[variableName];
  }
  return globalThis[variableName]?.toString();
}
function isDefinedAndNotEquals(value, expected) {
  return value !== void 0 && value !== expected;
}
function serializeInput(message) {
  if (typeof message === "undefined") {
    return "undefined";
  }
  if (message === null) {
    return "null";
  }
  if (typeof message === "string") {
    return message;
  }
  if (typeof message === "object") {
    return JSON.stringify(message);
  }
  return message.toString();
}

// node_modules/.pnpm/strict-event-emitter@0.5.1/node_modules/strict-event-emitter/lib/index.mjs
var MemoryLeakError = class extends Error {
  constructor(emitter, type, count) {
    super(`Possible EventEmitter memory leak detected. ${count} ${type.toString()} listeners added. Use emitter.setMaxListeners() to increase limit`);
    this.emitter = emitter;
    this.type = type;
    this.count = count;
    this.name = "MaxListenersExceededWarning";
  }
};
var _Emitter = class {
  static listenerCount(emitter, eventName) {
    return emitter.listenerCount(eventName);
  }
  constructor() {
    this.events = /* @__PURE__ */new Map();
    this.maxListeners = _Emitter.defaultMaxListeners;
    this.hasWarnedAboutPotentialMemoryLeak = false;
  }
  _emitInternalEvent(internalEventName, eventName, listener) {
    this.emit(internalEventName, ...[eventName, listener]);
  }
  _getListeners(eventName) {
    return Array.prototype.concat.apply([], this.events.get(eventName)) || [];
  }
  _removeListener(listeners, listener) {
    const index = listeners.indexOf(listener);
    if (index > -1) {
      listeners.splice(index, 1);
    }
    return [];
  }
  _wrapOnceListener(eventName, listener) {
    const onceListener = (...data) => {
      this.removeListener(eventName, onceListener);
      return listener.apply(this, data);
    };
    Object.defineProperty(onceListener, "name", {
      value: listener.name
    });
    return onceListener;
  }
  setMaxListeners(maxListeners) {
    this.maxListeners = maxListeners;
    return this;
  }
  /**
   * Returns the current max listener value for the `Emitter` which is
   * either set by `emitter.setMaxListeners(n)` or defaults to
   * `Emitter.defaultMaxListeners`.
   */
  getMaxListeners() {
    return this.maxListeners;
  }
  /**
   * Returns an array listing the events for which the emitter has registered listeners.
   * The values in the array will be strings or Symbols.
   */
  eventNames() {
    return Array.from(this.events.keys());
  }
  /**
   * Synchronously calls each of the listeners registered for the event named `eventName`,
   * in the order they were registered, passing the supplied arguments to each.
   * Returns `true` if the event has listeners, `false` otherwise.
   *
   * @example
   * const emitter = new Emitter<{ hello: [string] }>()
   * emitter.emit('hello', 'John')
   */
  emit(eventName, ...data) {
    const listeners = this._getListeners(eventName);
    listeners.forEach(listener => {
      listener.apply(this, data);
    });
    return listeners.length > 0;
  }
  addListener(eventName, listener) {
    this._emitInternalEvent("newListener", eventName, listener);
    const nextListeners = this._getListeners(eventName).concat(listener);
    this.events.set(eventName, nextListeners);
    if (this.maxListeners > 0 && this.listenerCount(eventName) > this.maxListeners && !this.hasWarnedAboutPotentialMemoryLeak) {
      this.hasWarnedAboutPotentialMemoryLeak = true;
      const memoryLeakWarning = new MemoryLeakError(this, eventName, this.listenerCount(eventName));
      console.warn(memoryLeakWarning);
    }
    return this;
  }
  on(eventName, listener) {
    return this.addListener(eventName, listener);
  }
  once(eventName, listener) {
    return this.addListener(eventName, this._wrapOnceListener(eventName, listener));
  }
  prependListener(eventName, listener) {
    const listeners = this._getListeners(eventName);
    if (listeners.length > 0) {
      const nextListeners = [listener].concat(listeners);
      this.events.set(eventName, nextListeners);
    } else {
      this.events.set(eventName, listeners.concat(listener));
    }
    return this;
  }
  prependOnceListener(eventName, listener) {
    return this.prependListener(eventName, this._wrapOnceListener(eventName, listener));
  }
  removeListener(eventName, listener) {
    const listeners = this._getListeners(eventName);
    if (listeners.length > 0) {
      this._removeListener(listeners, listener);
      this.events.set(eventName, listeners);
      this._emitInternalEvent("removeListener", eventName, listener);
    }
    return this;
  }
  /**
   * Alias for `emitter.removeListener()`.
   *
   * @example
   * emitter.off('hello', listener)
   */
  off(eventName, listener) {
    return this.removeListener(eventName, listener);
  }
  removeAllListeners(eventName) {
    if (eventName) {
      this.events.delete(eventName);
    } else {
      this.events.clear();
    }
    return this;
  }
  /**
   * Returns a copy of the array of listeners for the event named `eventName`.
   */
  listeners(eventName) {
    return Array.from(this._getListeners(eventName));
  }
  /**
   * Returns the number of listeners listening to the event named `eventName`.
   */
  listenerCount(eventName) {
    return this._getListeners(eventName).length;
  }
  rawListeners(eventName) {
    return this.listeners(eventName);
  }
};
var Emitter = _Emitter;
Emitter.defaultMaxListeners = 10;

// node_modules/.pnpm/@mswjs+interceptors@0.29.0/node_modules/@mswjs/interceptors/lib/browser/chunk-QED3Q6Z2.mjs
var INTERNAL_REQUEST_ID_HEADER_NAME = "x-interceptors-internal-request-id";
function getGlobalSymbol(symbol) {
  return (
    // @ts-ignore https://github.com/Microsoft/TypeScript/issues/24587
    globalThis[symbol] || void 0
  );
}
function setGlobalSymbol(symbol, value) {
  globalThis[symbol] = value;
}
function deleteGlobalSymbol(symbol) {
  delete globalThis[symbol];
}
var Interceptor = class {
  constructor(symbol) {
    this.symbol = symbol;
    this.readyState = "INACTIVE";
    this.emitter = new Emitter();
    this.subscriptions = [];
    this.logger = new Logger(symbol.description);
    this.emitter.setMaxListeners(0);
    this.logger.info("constructing the interceptor...");
  }
  /**
   * Determine if this interceptor can be applied
   * in the current environment.
   */
  checkEnvironment() {
    return true;
  }
  /**
   * Apply this interceptor to the current process.
   * Returns an already running interceptor instance if it's present.
   */
  apply() {
    const logger = this.logger.extend("apply");
    logger.info("applying the interceptor...");
    if (this.readyState === "APPLIED") {
      logger.info("intercepted already applied!");
      return;
    }
    const shouldApply = this.checkEnvironment();
    if (!shouldApply) {
      logger.info("the interceptor cannot be applied in this environment!");
      return;
    }
    this.readyState = "APPLYING";
    const runningInstance = this.getInstance();
    if (runningInstance) {
      logger.info("found a running instance, reusing...");
      this.on = (event, listener) => {
        logger.info('proxying the "%s" listener', event);
        runningInstance.emitter.addListener(event, listener);
        this.subscriptions.push(() => {
          runningInstance.emitter.removeListener(event, listener);
          logger.info('removed proxied "%s" listener!', event);
        });
        return this;
      };
      this.readyState = "APPLIED";
      return;
    }
    logger.info("no running instance found, setting up a new instance...");
    this.setup();
    this.setInstance();
    this.readyState = "APPLIED";
  }
  /**
   * Setup the module augments and stubs necessary for this interceptor.
   * This method is not run if there's a running interceptor instance
   * to prevent instantiating an interceptor multiple times.
   */
  setup() {}
  /**
   * Listen to the interceptor's public events.
   */
  on(event, listener) {
    const logger = this.logger.extend("on");
    if (this.readyState === "DISPOSING" || this.readyState === "DISPOSED") {
      logger.info("cannot listen to events, already disposed!");
      return this;
    }
    logger.info('adding "%s" event listener:', event, listener);
    this.emitter.on(event, listener);
    return this;
  }
  once(event, listener) {
    this.emitter.once(event, listener);
    return this;
  }
  off(event, listener) {
    this.emitter.off(event, listener);
    return this;
  }
  removeAllListeners(event) {
    this.emitter.removeAllListeners(event);
    return this;
  }
  /**
   * Disposes of any side-effects this interceptor has introduced.
   */
  dispose() {
    const logger = this.logger.extend("dispose");
    if (this.readyState === "DISPOSED") {
      logger.info("cannot dispose, already disposed!");
      return;
    }
    logger.info("disposing the interceptor...");
    this.readyState = "DISPOSING";
    if (!this.getInstance()) {
      logger.info("no interceptors running, skipping dispose...");
      return;
    }
    this.clearInstance();
    logger.info("global symbol deleted:", getGlobalSymbol(this.symbol));
    if (this.subscriptions.length > 0) {
      logger.info("disposing of %d subscriptions...", this.subscriptions.length);
      for (const dispose of this.subscriptions) {
        dispose();
      }
      this.subscriptions = [];
      logger.info("disposed of all subscriptions!", this.subscriptions.length);
    }
    this.emitter.removeAllListeners();
    logger.info("destroyed the listener!");
    this.readyState = "DISPOSED";
  }
  getInstance() {
    var _a;
    const instance = getGlobalSymbol(this.symbol);
    this.logger.info("retrieved global instance:", (_a = instance == null ? void 0 : instance.constructor) == null ? void 0 : _a.name);
    return instance;
  }
  setInstance() {
    setGlobalSymbol(this.symbol, this);
    this.logger.info("set global instance!", this.symbol.description);
  }
  clearInstance() {
    deleteGlobalSymbol(this.symbol);
    this.logger.info("cleared global instance!", this.symbol.description);
  }
};
function createRequestId() {
  return Math.random().toString(16).slice(2);
}

// node_modules/.pnpm/@mswjs+interceptors@0.29.0/node_modules/@mswjs/interceptors/lib/browser/index.mjs
var BatchInterceptor = class extends Interceptor {
  constructor(options) {
    BatchInterceptor.symbol = Symbol(options.name);
    super(BatchInterceptor.symbol);
    this.interceptors = options.interceptors;
  }
  setup() {
    const logger = this.logger.extend("setup");
    logger.info("applying all %d interceptors...", this.interceptors.length);
    for (const interceptor of this.interceptors) {
      logger.info('applying "%s" interceptor...', interceptor.constructor.name);
      interceptor.apply();
      logger.info("adding interceptor dispose subscription");
      this.subscriptions.push(() => interceptor.dispose());
    }
  }
  on(event, listener) {
    for (const interceptor of this.interceptors) {
      interceptor.on(event, listener);
    }
    return this;
  }
  once(event, listener) {
    for (const interceptor of this.interceptors) {
      interceptor.once(event, listener);
    }
    return this;
  }
  off(event, listener) {
    for (const interceptor of this.interceptors) {
      interceptor.off(event, listener);
    }
    return this;
  }
  removeAllListeners(event) {
    for (const interceptors of this.interceptors) {
      interceptors.removeAllListeners(event);
    }
    return this;
  }
};

// src/browser/setupWorker/start/createResponseListener.ts
function createResponseListener(context) {
  return (_, message) => {
    const {
      payload: responseJson
    } = message;
    const {
      requestId
    } = responseJson;
    const request = context.requests.get(requestId);
    context.requests.delete(requestId);
    if (responseJson.type?.includes("opaque")) {
      return;
    }
    const response = responseJson.status === 0 ? Response.error() : new Response(
    /**
     * Responses may be streams here, but when we create a response object
     * with null-body status codes, like 204, 205, 304 Response will
     * throw when passed a non-null body, so ensure it's null here
     * for those codes
     */
    isResponseWithoutBody(responseJson.status) ? null : responseJson.body, responseJson);
    if (!response.url) {
      Object.defineProperty(response, "url", {
        value: request.url,
        enumerable: true,
        writable: false
      });
    }
    context.emitter.emit(responseJson.isMockedResponse ? "response:mocked" : "response:bypass", {
      response,
      request,
      requestId: responseJson.requestId
    });
  };
}

// src/browser/setupWorker/start/utils/validateWorkerScope.ts
import { devUtils as devUtils6 } from '../core/utils/internal/devUtils.mjs';
function validateWorkerScope(registration, options) {
  if (!options?.quiet && !location.href.startsWith(registration.scope)) {
    devUtils6.warn(`Cannot intercept requests on this page because it's outside of the worker's scope ("${registration.scope}"). If you wish to mock API requests on this page, you must resolve this scope issue.

- (Recommended) Register the worker at the root level ("/") of your application.
- Set the "Service-Worker-Allowed" response header to allow out-of-scope workers.`);
  }
}

// src/browser/setupWorker/start/createStartHandler.ts
var createStartHandler = context => {
  return function start(options, customOptions) {
    const startWorkerInstance = async () => {
      context.events.removeAllListeners();
      context.workerChannel.on("REQUEST", createRequestListener(context, options));
      context.workerChannel.on("RESPONSE", createResponseListener(context));
      const instance = await getWorkerInstance(options.serviceWorker.url, options.serviceWorker.options, options.findWorker);
      const [worker, registration] = instance;
      if (!worker) {
        const missingWorkerMessage = customOptions?.findWorker ? devUtils7.formatMessage(`Failed to locate the Service Worker registration using a custom "findWorker" predicate.

Please ensure that the custom predicate properly locates the Service Worker registration at "%s".
More details: https://mswjs.io/docs/api/setup-worker/start#findworker
`, options.serviceWorker.url) : devUtils7.formatMessage(`Failed to locate the Service Worker registration.

This most likely means that the worker script URL "%s" cannot resolve against the actual public hostname (%s). This may happen if your application runs behind a proxy, or has a dynamic hostname.

Please consider using a custom "serviceWorker.url" option to point to the actual worker script location, or a custom "findWorker" option to resolve the Service Worker registration manually. More details: https://mswjs.io/docs/api/setup-worker/start`, options.serviceWorker.url, location.host);
        throw new Error(missingWorkerMessage);
      }
      context.worker = worker;
      context.registration = registration;
      context.events.addListener(window, "beforeunload", () => {
        if (worker.state !== "redundant") {
          context.workerChannel.send("CLIENT_CLOSED");
        }
        window.clearInterval(context.keepAliveInterval);
      });
      await checkWorkerIntegrity(context).catch(error2 => {
        devUtils7.error("Error while checking the worker script integrity. Please report this on GitHub (https://github.com/mswjs/msw/issues), including the original error below.");
        console.error(error2);
      });
      context.keepAliveInterval = window.setInterval(() => context.workerChannel.send("KEEPALIVE_REQUEST"), 5e3);
      validateWorkerScope(registration, context.startOptions);
      return registration;
    };
    const workerRegistration = startWorkerInstance().then(async registration => {
      const pendingInstance = registration.installing || registration.waiting;
      if (pendingInstance) {
        await new Promise(resolve => {
          pendingInstance.addEventListener("statechange", () => {
            if (pendingInstance.state === "activated") {
              return resolve();
            }
          });
        });
      }
      await enableMocking(context, options).catch(error2 => {
        throw new Error(`Failed to enable mocking: ${error2?.message}`);
      });
      return registration;
    });
    return workerRegistration;
  };
};

// src/browser/setupWorker/stop/createStop.ts
import { devUtils as devUtils9 } from '../core/utils/internal/devUtils.mjs';

// src/browser/setupWorker/stop/utils/printStopMessage.ts
import { devUtils as devUtils8 } from '../core/utils/internal/devUtils.mjs';
function printStopMessage(args = {}) {
  if (args.quiet) {
    return;
  }
  console.log(`%c${devUtils8.formatMessage("Mocking disabled.")}`, "color:orangered;font-weight:bold;");
}

// src/browser/setupWorker/stop/createStop.ts
var createStop = context => {
  return function stop() {
    if (!context.isMockingEnabled) {
      devUtils9.warn('Found a redundant "worker.stop()" call. Note that stopping the worker while mocking already stopped has no effect. Consider removing this "worker.stop()" call.');
      return;
    }
    context.workerChannel.send("MOCK_DEACTIVATE");
    context.isMockingEnabled = false;
    window.clearInterval(context.keepAliveInterval);
    printStopMessage({
      quiet: context.startOptions?.quiet
    });
  };
};

// src/browser/setupWorker/start/utils/prepareStartHandler.ts
import { mergeRight } from '../core/utils/internal/mergeRight.mjs';
var DEFAULT_START_OPTIONS = {
  serviceWorker: {
    url: "/mockServiceWorker.js",
    options: null
  },
  quiet: false,
  waitUntilReady: true,
  onUnhandledRequest: "warn",
  findWorker(scriptURL, mockServiceWorkerUrl) {
    return scriptURL === mockServiceWorkerUrl;
  }
};

// node_modules/.pnpm/@open-draft+deferred-promise@2.2.0/node_modules/@open-draft/deferred-promise/build/index.mjs
function createDeferredExecutor() {
  const executor = (resolve, reject) => {
    executor.state = "pending";
    executor.resolve = data => {
      if (executor.state !== "pending") {
        return;
      }
      executor.result = data;
      const onFulfilled = value => {
        executor.state = "fulfilled";
        return value;
      };
      return resolve(data instanceof Promise ? data : Promise.resolve(data).then(onFulfilled));
    };
    executor.reject = reason => {
      if (executor.state !== "pending") {
        return;
      }
      queueMicrotask(() => {
        executor.state = "rejected";
      });
      return reject(executor.rejectionReason = reason);
    };
  };
  return executor;
}
var DeferredPromise = class extends Promise {
  #executor;
  resolve;
  reject;
  constructor(executor = null) {
    const deferredExecutor = createDeferredExecutor();
    super((originalResolve, originalReject) => {
      deferredExecutor(originalResolve, originalReject);
      executor?.(deferredExecutor.resolve, deferredExecutor.reject);
    });
    this.#executor = deferredExecutor;
    this.resolve = this.#executor.resolve;
    this.reject = this.#executor.reject;
  }
  get state() {
    return this.#executor.state;
  }
  get rejectionReason() {
    return this.#executor.rejectionReason;
  }
  then(onFulfilled, onRejected) {
    return this.#decorate(super.then(onFulfilled, onRejected));
  }
  catch(onRejected) {
    return this.#decorate(super.catch(onRejected));
  }
  finally(onfinally) {
    return this.#decorate(super.finally(onfinally));
  }
  #decorate(promise) {
    return Object.defineProperties(promise, {
      resolve: {
        configurable: true,
        value: this.resolve
      },
      reject: {
        configurable: true,
        value: this.reject
      }
    });
  }
};

// node_modules/.pnpm/@mswjs+interceptors@0.29.0/node_modules/@mswjs/interceptors/lib/browser/chunk-OUWBQF3Z.mjs
var RequestController = class {
  constructor(request) {
    this.request = request;
    this.responsePromise = new DeferredPromise();
  }
  respondWith(response) {
    invariant(this.responsePromise.state === "pending", 'Failed to respond to "%s %s" request: the "request" event has already been responded to.', this.request.method, this.request.url);
    this.responsePromise.resolve(response);
  }
};
function toInteractiveRequest(request) {
  const requestController = new RequestController(request);
  Reflect.set(request, "respondWith", requestController.respondWith.bind(requestController));
  return {
    interactiveRequest: request,
    requestController
  };
}
async function emitAsync(emitter, eventName, ...data) {
  const listners = emitter.listeners(eventName);
  if (listners.length === 0) {
    return;
  }
  for (const listener of listners) {
    await listener.apply(emitter, data);
  }
}

// node_modules/.pnpm/@mswjs+interceptors@0.29.0/node_modules/@mswjs/interceptors/lib/browser/chunk-MAEPOYB6.mjs
function canParseUrl(url) {
  try {
    new URL(url);
    return true;
  } catch (_error) {
    return false;
  }
}
var _FetchInterceptor = class extends Interceptor {
  constructor() {
    super(_FetchInterceptor.symbol);
  }
  checkEnvironment() {
    return typeof globalThis !== "undefined" && typeof globalThis.fetch !== "undefined";
  }
  async setup() {
    const pureFetch = globalThis.fetch;
    invariant(!pureFetch[IS_PATCHED_MODULE], 'Failed to patch the "fetch" module: already patched.');
    globalThis.fetch = async (input, init) => {
      var _a;
      const requestId = createRequestId();
      const resolvedInput = typeof input === "string" && typeof location !== "undefined" && !canParseUrl(input) ? new URL(input, location.origin) : input;
      const request = new Request(resolvedInput, init);
      this.logger.info("[%s] %s", request.method, request.url);
      const {
        interactiveRequest,
        requestController
      } = toInteractiveRequest(request);
      this.logger.info('emitting the "request" event for %d listener(s)...', this.emitter.listenerCount("request"));
      this.emitter.once("request", ({
        requestId: pendingRequestId
      }) => {
        if (pendingRequestId !== requestId) {
          return;
        }
        if (requestController.responsePromise.state === "pending") {
          requestController.responsePromise.resolve(void 0);
        }
      });
      this.logger.info("awaiting for the mocked response...");
      const signal = interactiveRequest.signal;
      const requestAborted = new DeferredPromise();
      if (signal) {
        signal.addEventListener("abort", () => {
          requestAborted.reject(signal.reason);
        }, {
          once: true
        });
      }
      const responsePromise = new DeferredPromise();
      const respondWith = response => {
        this.logger.info("responding with a mock response:", response);
        if (this.emitter.listenerCount("response") > 0) {
          this.logger.info('emitting the "response" event...');
          const responseClone = response.clone();
          this.emitter.emit("response", {
            response: responseClone,
            isMockedResponse: true,
            request: interactiveRequest,
            requestId
          });
        }
        Object.defineProperty(response, "url", {
          writable: false,
          enumerable: true,
          configurable: false,
          value: request.url
        });
        responsePromise.resolve(response);
      };
      const errorWith = reason => {
        responsePromise.reject(reason);
      };
      const resolverResult = await until(async () => {
        const listenersFinished = emitAsync(this.emitter, "request", {
          request: interactiveRequest,
          requestId
        });
        await Promise.race([requestAborted,
        // Put the listeners invocation Promise in the same race condition
        // with the request abort Promise because otherwise awaiting the listeners
        // would always yield some response (or undefined).
        listenersFinished, requestController.responsePromise]);
        this.logger.info("all request listeners have been resolved!");
        const mockedResponse2 = await requestController.responsePromise;
        this.logger.info("event.respondWith called with:", mockedResponse2);
        return mockedResponse2;
      });
      if (requestAborted.state === "rejected") {
        this.logger.info("request has been aborted:", requestAborted.rejectionReason);
        responsePromise.reject(requestAborted.rejectionReason);
        return responsePromise;
      }
      if (resolverResult.error) {
        this.logger.info("request listerner threw an error:", resolverResult.error);
        if (resolverResult.error instanceof Response) {
          if (isResponseError(resolverResult.error)) {
            errorWith(createNetworkError(resolverResult.error));
          } else {
            respondWith(resolverResult.error);
          }
        }
        if (this.emitter.listenerCount("unhandledException") > 0) {
          await emitAsync(this.emitter, "unhandledException", {
            error: resolverResult.error,
            request,
            requestId,
            controller: {
              respondWith,
              errorWith
            }
          });
          if (responsePromise.state !== "pending") {
            return responsePromise;
          }
        }
        respondWith(createServerErrorResponse(resolverResult.error));
        return responsePromise;
      }
      const mockedResponse = resolverResult.data;
      if (mockedResponse && !((_a = request.signal) == null ? void 0 : _a.aborted)) {
        this.logger.info("received mocked response:", mockedResponse);
        if (isResponseError(mockedResponse)) {
          this.logger.info("received a network error response, rejecting the request promise...");
          errorWith(createNetworkError(mockedResponse));
        } else {
          respondWith(mockedResponse);
        }
        return responsePromise;
      }
      this.logger.info("no mocked response received!");
      return pureFetch(request).then(response => {
        this.logger.info("original fetch performed", response);
        if (this.emitter.listenerCount("response") > 0) {
          this.logger.info('emitting the "response" event...');
          const responseClone = response.clone();
          this.emitter.emit("response", {
            response: responseClone,
            isMockedResponse: false,
            request: interactiveRequest,
            requestId
          });
        }
        return response;
      });
    };
    Object.defineProperty(globalThis.fetch, IS_PATCHED_MODULE, {
      enumerable: true,
      configurable: true,
      value: true
    });
    this.subscriptions.push(() => {
      Object.defineProperty(globalThis.fetch, IS_PATCHED_MODULE, {
        value: void 0
      });
      globalThis.fetch = pureFetch;
      this.logger.info('restored native "globalThis.fetch"!', globalThis.fetch.name);
    });
  }
};
var FetchInterceptor = _FetchInterceptor;
FetchInterceptor.symbol = Symbol("fetch");
function createNetworkError(cause) {
  return Object.assign(new TypeError("Failed to fetch"), {
    cause
  });
}

// node_modules/.pnpm/@mswjs+interceptors@0.29.0/node_modules/@mswjs/interceptors/lib/browser/chunk-732REFPX.mjs
function concatArrayBuffer(left, right) {
  const result = new Uint8Array(left.byteLength + right.byteLength);
  result.set(left, 0);
  result.set(right, left.byteLength);
  return result;
}
var EventPolyfill = class {
  constructor(type, options) {
    this.AT_TARGET = 0;
    this.BUBBLING_PHASE = 0;
    this.CAPTURING_PHASE = 0;
    this.NONE = 0;
    this.type = "";
    this.srcElement = null;
    this.currentTarget = null;
    this.eventPhase = 0;
    this.isTrusted = true;
    this.composed = false;
    this.cancelable = true;
    this.defaultPrevented = false;
    this.bubbles = true;
    this.lengthComputable = true;
    this.loaded = 0;
    this.total = 0;
    this.cancelBubble = false;
    this.returnValue = true;
    this.type = type;
    this.target = (options == null ? void 0 : options.target) || null;
    this.currentTarget = (options == null ? void 0 : options.currentTarget) || null;
    this.timeStamp = Date.now();
  }
  composedPath() {
    return [];
  }
  initEvent(type, bubbles, cancelable) {
    this.type = type;
    this.bubbles = !!bubbles;
    this.cancelable = !!cancelable;
  }
  preventDefault() {
    this.defaultPrevented = true;
  }
  stopPropagation() {}
  stopImmediatePropagation() {}
};
var ProgressEventPolyfill = class extends EventPolyfill {
  constructor(type, init) {
    super(type);
    this.lengthComputable = (init == null ? void 0 : init.lengthComputable) || false;
    this.composed = (init == null ? void 0 : init.composed) || false;
    this.loaded = (init == null ? void 0 : init.loaded) || 0;
    this.total = (init == null ? void 0 : init.total) || 0;
  }
};
var SUPPORTS_PROGRESS_EVENT = typeof ProgressEvent !== "undefined";
function createEvent(target, type, init) {
  const progressEvents = ["error", "progress", "loadstart", "loadend", "load", "timeout", "abort"];
  const ProgressEventClass = SUPPORTS_PROGRESS_EVENT ? ProgressEvent : ProgressEventPolyfill;
  const event = progressEvents.includes(type) ? new ProgressEventClass(type, {
    lengthComputable: true,
    loaded: (init == null ? void 0 : init.loaded) || 0,
    total: (init == null ? void 0 : init.total) || 0
  }) : new EventPolyfill(type, {
    target,
    currentTarget: target
  });
  return event;
}
function findPropertySource(target, propertyName) {
  if (!(propertyName in target)) {
    return null;
  }
  const hasProperty = Object.prototype.hasOwnProperty.call(target, propertyName);
  if (hasProperty) {
    return target;
  }
  const prototype = Reflect.getPrototypeOf(target);
  return prototype ? findPropertySource(prototype, propertyName) : null;
}
function createProxy(target, options) {
  const proxy = new Proxy(target, optionsToProxyHandler(options));
  return proxy;
}
function optionsToProxyHandler(options) {
  const {
    constructorCall,
    methodCall,
    getProperty,
    setProperty
  } = options;
  const handler = {};
  if (typeof constructorCall !== "undefined") {
    handler.construct = function (target, args, newTarget) {
      const next = Reflect.construct.bind(null, target, args, newTarget);
      return constructorCall.call(newTarget, args, next);
    };
  }
  handler.set = function (target, propertyName, nextValue) {
    const next = () => {
      const propertySource = findPropertySource(target, propertyName) || target;
      const ownDescriptors = Reflect.getOwnPropertyDescriptor(propertySource, propertyName);
      if (typeof (ownDescriptors == null ? void 0 : ownDescriptors.set) !== "undefined") {
        ownDescriptors.set.apply(target, [nextValue]);
        return true;
      }
      return Reflect.defineProperty(propertySource, propertyName, {
        writable: true,
        enumerable: true,
        configurable: true,
        value: nextValue
      });
    };
    if (typeof setProperty !== "undefined") {
      return setProperty.call(target, [propertyName, nextValue], next);
    }
    return next();
  };
  handler.get = function (target, propertyName, receiver) {
    const next = () => target[propertyName];
    const value = typeof getProperty !== "undefined" ? getProperty.call(target, [propertyName, receiver], next) : next();
    if (typeof value === "function") {
      return (...args) => {
        const next2 = value.bind(target, ...args);
        if (typeof methodCall !== "undefined") {
          return methodCall.call(target, [propertyName, args], next2);
        }
        return next2();
      };
    }
    return value;
  };
  return handler;
}
function isDomParserSupportedType(type) {
  const supportedTypes = ["application/xhtml+xml", "application/xml", "image/svg+xml", "text/html", "text/xml"];
  return supportedTypes.some(supportedType => {
    return type.startsWith(supportedType);
  });
}
function parseJson(data) {
  try {
    const json = JSON.parse(data);
    return json;
  } catch (_) {
    return null;
  }
}
function createResponse(request, body) {
  const responseBodyOrNull = isResponseWithoutBody(request.status) ? null : body;
  return new Response(responseBodyOrNull, {
    status: request.status,
    statusText: request.statusText,
    headers: createHeadersFromXMLHttpReqestHeaders(request.getAllResponseHeaders())
  });
}
function createHeadersFromXMLHttpReqestHeaders(headersString) {
  const headers = new Headers();
  const lines = headersString.split(/[\r\n]+/);
  for (const line of lines) {
    if (line.trim() === "") {
      continue;
    }
    const [name, ...parts] = line.split(": ");
    const value = parts.join(": ");
    headers.append(name, value);
  }
  return headers;
}
var IS_MOCKED_RESPONSE = Symbol("isMockedResponse");
var IS_NODE2 = isNodeProcess();
var XMLHttpRequestController = class {
  constructor(initialRequest, logger) {
    this.initialRequest = initialRequest;
    this.logger = logger;
    this.method = "GET";
    this.url = null;
    this.events = /* @__PURE__ */new Map();
    this.requestId = createRequestId();
    this.requestHeaders = new Headers();
    this.responseBuffer = new Uint8Array();
    this.request = createProxy(initialRequest, {
      setProperty: ([propertyName, nextValue], invoke) => {
        switch (propertyName) {
          case "ontimeout":
            {
              const eventName = propertyName.slice(2);
              this.request.addEventListener(eventName, nextValue);
              return invoke();
            }
          default:
            {
              return invoke();
            }
        }
      },
      methodCall: ([methodName, args], invoke) => {
        var _a;
        switch (methodName) {
          case "open":
            {
              const [method, url] = args;
              if (typeof url === "undefined") {
                this.method = "GET";
                this.url = toAbsoluteUrl(method);
              } else {
                this.method = method;
                this.url = toAbsoluteUrl(url);
              }
              this.logger = this.logger.extend(`${this.method} ${this.url.href}`);
              this.logger.info("open", this.method, this.url.href);
              return invoke();
            }
          case "addEventListener":
            {
              const [eventName, listener] = args;
              this.registerEvent(eventName, listener);
              this.logger.info("addEventListener", eventName, listener);
              return invoke();
            }
          case "setRequestHeader":
            {
              const [name, value] = args;
              this.requestHeaders.set(name, value);
              this.logger.info("setRequestHeader", name, value);
              return invoke();
            }
          case "send":
            {
              const [body] = args;
              if (body != null) {
                this.requestBody = typeof body === "string" ? encodeBuffer(body) : body;
              }
              this.request.addEventListener("load", () => {
                if (typeof this.onResponse !== "undefined") {
                  const fetchResponse = createResponse(this.request,
                  /**
                   * The `response` property is the right way to read
                   * the ambiguous response body, as the request's "responseType" may differ.
                   * @see https://xhr.spec.whatwg.org/#the-response-attribute
                   */
                  this.request.response);
                  this.onResponse.call(this, {
                    response: fetchResponse,
                    isMockedResponse: IS_MOCKED_RESPONSE in this.request,
                    request: fetchRequest,
                    requestId: this.requestId
                  });
                }
              });
              const fetchRequest = this.toFetchApiRequest();
              const onceRequestSettled = ((_a = this.onRequest) == null ? void 0 : _a.call(this, {
                request: fetchRequest,
                requestId: this.requestId
              })) || Promise.resolve();
              onceRequestSettled.finally(() => {
                if (this.request.readyState < this.request.LOADING) {
                  this.logger.info("request callback settled but request has not been handled (readystate %d), performing as-is...", this.request.readyState);
                  if (IS_NODE2) {
                    this.request.setRequestHeader(INTERNAL_REQUEST_ID_HEADER_NAME, this.requestId);
                  }
                  return invoke();
                }
              });
              break;
            }
          default:
            {
              return invoke();
            }
        }
      }
    });
  }
  registerEvent(eventName, listener) {
    const prevEvents = this.events.get(eventName) || [];
    const nextEvents = prevEvents.concat(listener);
    this.events.set(eventName, nextEvents);
    this.logger.info('registered event "%s"', eventName, listener);
  }
  /**
   * Responds to the current request with the given
   * Fetch API `Response` instance.
   */
  respondWith(response) {
    this.logger.info("responding with a mocked response: %d %s", response.status, response.statusText);
    define(this.request, IS_MOCKED_RESPONSE, true);
    define(this.request, "status", response.status);
    define(this.request, "statusText", response.statusText);
    define(this.request, "responseURL", this.url.href);
    this.request.getResponseHeader = new Proxy(this.request.getResponseHeader, {
      apply: (_, __, args) => {
        this.logger.info("getResponseHeader", args[0]);
        if (this.request.readyState < this.request.HEADERS_RECEIVED) {
          this.logger.info("headers not received yet, returning null");
          return null;
        }
        const headerValue = response.headers.get(args[0]);
        this.logger.info('resolved response header "%s" to', args[0], headerValue);
        return headerValue;
      }
    });
    this.request.getAllResponseHeaders = new Proxy(this.request.getAllResponseHeaders, {
      apply: () => {
        this.logger.info("getAllResponseHeaders");
        if (this.request.readyState < this.request.HEADERS_RECEIVED) {
          this.logger.info("headers not received yet, returning empty string");
          return "";
        }
        const headersList = Array.from(response.headers.entries());
        const allHeaders = headersList.map(([headerName, headerValue]) => {
          return `${headerName}: ${headerValue}`;
        }).join("\r\n");
        this.logger.info("resolved all response headers to", allHeaders);
        return allHeaders;
      }
    });
    Object.defineProperties(this.request, {
      response: {
        enumerable: true,
        configurable: false,
        get: () => this.response
      },
      responseText: {
        enumerable: true,
        configurable: false,
        get: () => this.responseText
      },
      responseXML: {
        enumerable: true,
        configurable: false,
        get: () => this.responseXML
      }
    });
    const totalResponseBodyLength = response.headers.has("Content-Length") ? Number(response.headers.get("Content-Length")) : (
    /**
     * @todo Infer the response body length from the response body.
     */
    void 0);
    this.logger.info("calculated response body length", totalResponseBodyLength);
    this.trigger("loadstart", {
      loaded: 0,
      total: totalResponseBodyLength
    });
    this.setReadyState(this.request.HEADERS_RECEIVED);
    this.setReadyState(this.request.LOADING);
    const finalizeResponse = () => {
      this.logger.info("finalizing the mocked response...");
      this.setReadyState(this.request.DONE);
      this.trigger("load", {
        loaded: this.responseBuffer.byteLength,
        total: totalResponseBodyLength
      });
      this.trigger("loadend", {
        loaded: this.responseBuffer.byteLength,
        total: totalResponseBodyLength
      });
    };
    if (response.body) {
      this.logger.info("mocked response has body, streaming...");
      const reader = response.body.getReader();
      const readNextResponseBodyChunk = async () => {
        const {
          value,
          done
        } = await reader.read();
        if (done) {
          this.logger.info("response body stream done!");
          finalizeResponse();
          return;
        }
        if (value) {
          this.logger.info("read response body chunk:", value);
          this.responseBuffer = concatArrayBuffer(this.responseBuffer, value);
          this.trigger("progress", {
            loaded: this.responseBuffer.byteLength,
            total: totalResponseBodyLength
          });
        }
        readNextResponseBodyChunk();
      };
      readNextResponseBodyChunk();
    } else {
      finalizeResponse();
    }
  }
  responseBufferToText() {
    return decodeBuffer(this.responseBuffer);
  }
  get response() {
    this.logger.info("getResponse (responseType: %s)", this.request.responseType);
    if (this.request.readyState !== this.request.DONE) {
      return null;
    }
    switch (this.request.responseType) {
      case "json":
        {
          const responseJson = parseJson(this.responseBufferToText());
          this.logger.info("resolved response JSON", responseJson);
          return responseJson;
        }
      case "arraybuffer":
        {
          const arrayBuffer = toArrayBuffer(this.responseBuffer);
          this.logger.info("resolved response ArrayBuffer", arrayBuffer);
          return arrayBuffer;
        }
      case "blob":
        {
          const mimeType = this.request.getResponseHeader("Content-Type") || "text/plain";
          const responseBlob = new Blob([this.responseBufferToText()], {
            type: mimeType
          });
          this.logger.info("resolved response Blob (mime type: %s)", responseBlob, mimeType);
          return responseBlob;
        }
      default:
        {
          const responseText = this.responseBufferToText();
          this.logger.info('resolving "%s" response type as text', this.request.responseType, responseText);
          return responseText;
        }
    }
  }
  get responseText() {
    invariant(this.request.responseType === "" || this.request.responseType === "text", "InvalidStateError: The object is in invalid state.");
    if (this.request.readyState !== this.request.LOADING && this.request.readyState !== this.request.DONE) {
      return "";
    }
    const responseText = this.responseBufferToText();
    this.logger.info('getResponseText: "%s"', responseText);
    return responseText;
  }
  get responseXML() {
    invariant(this.request.responseType === "" || this.request.responseType === "document", "InvalidStateError: The object is in invalid state.");
    if (this.request.readyState !== this.request.DONE) {
      return null;
    }
    const contentType = this.request.getResponseHeader("Content-Type") || "";
    if (typeof DOMParser === "undefined") {
      console.warn("Cannot retrieve XMLHttpRequest response body as XML: DOMParser is not defined. You are likely using an environment that is not browser or does not polyfill browser globals correctly.");
      return null;
    }
    if (isDomParserSupportedType(contentType)) {
      return new DOMParser().parseFromString(this.responseBufferToText(), contentType);
    }
    return null;
  }
  errorWith(error2) {
    this.logger.info("responding with an error");
    this.setReadyState(this.request.DONE);
    this.trigger("error");
    this.trigger("loadend");
  }
  /**
   * Transitions this request's `readyState` to the given one.
   */
  setReadyState(nextReadyState) {
    this.logger.info("setReadyState: %d -> %d", this.request.readyState, nextReadyState);
    if (this.request.readyState === nextReadyState) {
      this.logger.info("ready state identical, skipping transition...");
      return;
    }
    define(this.request, "readyState", nextReadyState);
    this.logger.info("set readyState to: %d", nextReadyState);
    if (nextReadyState !== this.request.UNSENT) {
      this.logger.info('triggerring "readystatechange" event...');
      this.trigger("readystatechange");
    }
  }
  /**
   * Triggers given event on the `XMLHttpRequest` instance.
   */
  trigger(eventName, options) {
    const callback = this.request[`on${eventName}`];
    const event = createEvent(this.request, eventName, options);
    this.logger.info('trigger "%s"', eventName, options || "");
    if (typeof callback === "function") {
      this.logger.info('found a direct "%s" callback, calling...', eventName);
      callback.call(this.request, event);
    }
    for (const [registeredEventName, listeners] of this.events) {
      if (registeredEventName === eventName) {
        this.logger.info('found %d listener(s) for "%s" event, calling...', listeners.length, eventName);
        listeners.forEach(listener => listener.call(this.request, event));
      }
    }
  }
  /**
   * Converts this `XMLHttpRequest` instance into a Fetch API `Request` instance.
   */
  toFetchApiRequest() {
    this.logger.info("converting request to a Fetch API Request...");
    const fetchRequest = new Request(this.url.href, {
      method: this.method,
      headers: this.requestHeaders,
      /**
       * @see https://xhr.spec.whatwg.org/#cross-origin-credentials
       */
      credentials: this.request.withCredentials ? "include" : "same-origin",
      body: ["GET", "HEAD"].includes(this.method) ? null : this.requestBody
    });
    const proxyHeaders = createProxy(fetchRequest.headers, {
      methodCall: ([methodName, args], invoke) => {
        switch (methodName) {
          case "append":
          case "set":
            {
              const [headerName, headerValue] = args;
              this.request.setRequestHeader(headerName, headerValue);
              break;
            }
          case "delete":
            {
              const [headerName] = args;
              console.warn(`XMLHttpRequest: Cannot remove a "${headerName}" header from the Fetch API representation of the "${fetchRequest.method} ${fetchRequest.url}" request. XMLHttpRequest headers cannot be removed.`);
              break;
            }
        }
        return invoke();
      }
    });
    define(fetchRequest, "headers", proxyHeaders);
    this.logger.info("converted request to a Fetch API Request!", fetchRequest);
    return fetchRequest;
  }
};
function toAbsoluteUrl(url) {
  if (typeof location === "undefined") {
    return new URL(url);
  }
  return new URL(url.toString(), location.href);
}
function define(target, property, value) {
  Reflect.defineProperty(target, property, {
    // Ensure writable properties to allow redefining readonly properties.
    writable: true,
    enumerable: true,
    value
  });
}
function createXMLHttpRequestProxy({
  emitter,
  logger
}) {
  const XMLHttpRequestProxy = new Proxy(globalThis.XMLHttpRequest, {
    construct(target, args, newTarget) {
      logger.info("constructed new XMLHttpRequest");
      const originalRequest = Reflect.construct(target, args, newTarget);
      const prototypeDescriptors = Object.getOwnPropertyDescriptors(target.prototype);
      for (const propertyName in prototypeDescriptors) {
        Reflect.defineProperty(originalRequest, propertyName, prototypeDescriptors[propertyName]);
      }
      const xhrRequestController = new XMLHttpRequestController(originalRequest, logger);
      xhrRequestController.onRequest = async function ({
        request,
        requestId
      }) {
        const {
          interactiveRequest,
          requestController
        } = toInteractiveRequest(request);
        this.logger.info("awaiting mocked response...");
        emitter.once("request", ({
          requestId: pendingRequestId
        }) => {
          if (pendingRequestId !== requestId) {
            return;
          }
          if (requestController.responsePromise.state === "pending") {
            requestController.respondWith(void 0);
          }
        });
        const resolverResult = await until(async () => {
          this.logger.info('emitting the "request" event for %s listener(s)...', emitter.listenerCount("request"));
          await emitAsync(emitter, "request", {
            request: interactiveRequest,
            requestId
          });
          this.logger.info('all "request" listeners settled!');
          const mockedResponse2 = await requestController.responsePromise;
          this.logger.info("event.respondWith called with:", mockedResponse2);
          return mockedResponse2;
        });
        if (resolverResult.error) {
          this.logger.info("request listener threw an exception, aborting request...", resolverResult.error);
          if (resolverResult.error instanceof Response) {
            if (isResponseError(resolverResult.error)) {
              xhrRequestController.errorWith(new TypeError("Network error"));
            } else {
              this.respondWith(resolverResult.error);
            }
            return;
          }
          if (emitter.listenerCount("unhandledException") > 0) {
            await emitAsync(emitter, "unhandledException", {
              error: resolverResult.error,
              request,
              requestId,
              controller: {
                respondWith: xhrRequestController.respondWith.bind(xhrRequestController),
                errorWith: xhrRequestController.errorWith.bind(xhrRequestController)
              }
            });
            if (originalRequest.readyState > XMLHttpRequest.OPENED) {
              return;
            }
          }
          xhrRequestController.respondWith(createServerErrorResponse(resolverResult.error));
          return;
        }
        const mockedResponse = resolverResult.data;
        if (typeof mockedResponse !== "undefined") {
          this.logger.info("received mocked response: %d %s", mockedResponse.status, mockedResponse.statusText);
          if (isResponseError(mockedResponse)) {
            this.logger.info("received a network error response, rejecting the request promise...");
            xhrRequestController.errorWith(new TypeError("Network error"));
            return;
          }
          return xhrRequestController.respondWith(mockedResponse);
        }
        this.logger.info("no mocked response received, performing request as-is...");
      };
      xhrRequestController.onResponse = async function ({
        response,
        isMockedResponse,
        request,
        requestId
      }) {
        this.logger.info('emitting the "response" event for %s listener(s)...', emitter.listenerCount("response"));
        emitter.emit("response", {
          response,
          isMockedResponse,
          request,
          requestId
        });
      };
      return xhrRequestController.request;
    }
  });
  return XMLHttpRequestProxy;
}
var _XMLHttpRequestInterceptor = class extends Interceptor {
  constructor() {
    super(_XMLHttpRequestInterceptor.interceptorSymbol);
  }
  checkEnvironment() {
    return typeof globalThis.XMLHttpRequest !== "undefined";
  }
  setup() {
    const logger = this.logger.extend("setup");
    logger.info('patching "XMLHttpRequest" module...');
    const PureXMLHttpRequest = globalThis.XMLHttpRequest;
    invariant(!PureXMLHttpRequest[IS_PATCHED_MODULE], 'Failed to patch the "XMLHttpRequest" module: already patched.');
    globalThis.XMLHttpRequest = createXMLHttpRequestProxy({
      emitter: this.emitter,
      logger: this.logger
    });
    logger.info('native "XMLHttpRequest" module patched!', globalThis.XMLHttpRequest.name);
    Object.defineProperty(globalThis.XMLHttpRequest, IS_PATCHED_MODULE, {
      enumerable: true,
      configurable: true,
      value: true
    });
    this.subscriptions.push(() => {
      Object.defineProperty(globalThis.XMLHttpRequest, IS_PATCHED_MODULE, {
        value: void 0
      });
      globalThis.XMLHttpRequest = PureXMLHttpRequest;
      logger.info('native "XMLHttpRequest" module restored!', globalThis.XMLHttpRequest.name);
    });
  }
};
var XMLHttpRequestInterceptor = _XMLHttpRequestInterceptor;
XMLHttpRequestInterceptor.interceptorSymbol = Symbol("xhr");

// src/browser/setupWorker/start/createFallbackRequestListener.ts
import { handleRequest as handleRequest2 } from '../core/utils/handleRequest.mjs';
function createFallbackRequestListener(context, options) {
  const interceptor = new BatchInterceptor({
    name: "fallback",
    interceptors: [new FetchInterceptor(), new XMLHttpRequestInterceptor()]
  });
  interceptor.on("request", async ({
    request,
    requestId
  }) => {
    const requestCloneForLogs = request.clone();
    const response = await handleRequest2(request, requestId, context.getRequestHandlers(), options, context.emitter, {
      onMockedResponse(_, {
        handler,
        parsedResult
      }) {
        if (!options.quiet) {
          context.emitter.once("response:mocked", ({
            response: response2
          }) => {
            handler.log({
              request: requestCloneForLogs,
              response: response2,
              parsedResult
            });
          });
        }
      }
    });
    if (response) {
      request.respondWith(response);
    }
  });
  interceptor.on("response", ({
    response,
    isMockedResponse,
    request,
    requestId
  }) => {
    context.emitter.emit(isMockedResponse ? "response:mocked" : "response:bypass", {
      response,
      request,
      requestId
    });
  });
  interceptor.apply();
  return interceptor;
}

// src/browser/setupWorker/start/createFallbackStart.ts
function createFallbackStart(context) {
  return async function start(options) {
    context.fallbackInterceptor = createFallbackRequestListener(context, options);
    printStartMessage({
      message: "Mocking enabled (fallback mode).",
      quiet: options.quiet
    });
    return void 0;
  };
}

// src/browser/setupWorker/stop/createFallbackStop.ts
function createFallbackStop(context) {
  return function stop() {
    context.fallbackInterceptor?.dispose();
    printStopMessage({
      quiet: context.startOptions?.quiet
    });
  };
}

// src/browser/setupWorker/setupWorker.ts
import { devUtils as devUtils10 } from '../core/utils/internal/devUtils.mjs';
import { SetupApi } from '../core/SetupApi.mjs';
import { mergeRight as mergeRight2 } from '../core/utils/internal/mergeRight.mjs';

// src/browser/utils/supportsReadableStreamTransfer.ts
function supportsReadableStreamTransfer() {
  try {
    const stream = new ReadableStream({
      start: controller => controller.close()
    });
    const message = new MessageChannel();
    message.port1.postMessage(stream, [stream]);
    return true;
  } catch (error2) {
    return false;
  }
}

// src/browser/setupWorker/setupWorker.ts
var SetupWorkerApi = class extends SetupApi {
  context;
  startHandler = null;
  stopHandler = null;
  listeners;
  constructor(...handlers) {
    super(...handlers);
    invariant(!isNodeProcess(), devUtils10.formatMessage("Failed to execute `setupWorker` in a non-browser environment. Consider using `setupServer` for Node.js environment instead."));
    this.listeners = [];
    this.context = this.createWorkerContext();
  }
  createWorkerContext() {
    const context = {
      // Mocking is not considered enabled until the worker
      // signals back the successful activation event.
      isMockingEnabled: false,
      startOptions: null,
      worker: null,
      getRequestHandlers: () => {
        return this.handlersController.currentHandlers();
      },
      registration: null,
      requests: /* @__PURE__ */new Map(),
      emitter: this.emitter,
      workerChannel: {
        on: (eventType, callback) => {
          this.context.events.addListener(navigator.serviceWorker, "message", event => {
            if (event.source !== this.context.worker) {
              return;
            }
            const message = event.data;
            if (!message) {
              return;
            }
            if (message.type === eventType) {
              callback(event, message);
            }
          });
        },
        send: type => {
          this.context.worker?.postMessage(type);
        }
      },
      events: {
        addListener: (target, eventType, callback) => {
          target.addEventListener(eventType, callback);
          this.listeners.push({
            eventType,
            target,
            callback
          });
          return () => {
            target.removeEventListener(eventType, callback);
          };
        },
        removeAllListeners: () => {
          for (const {
            target,
            eventType,
            callback
          } of this.listeners) {
            target.removeEventListener(eventType, callback);
          }
          this.listeners = [];
        },
        once: eventType => {
          const bindings = [];
          return new Promise((resolve, reject) => {
            const handleIncomingMessage = event => {
              try {
                const message = event.data;
                if (message.type === eventType) {
                  resolve(message);
                }
              } catch (error2) {
                reject(error2);
              }
            };
            bindings.push(this.context.events.addListener(navigator.serviceWorker, "message", handleIncomingMessage), this.context.events.addListener(navigator.serviceWorker, "messageerror", reject));
          }).finally(() => {
            bindings.forEach(unbind => unbind());
          });
        }
      },
      supports: {
        serviceWorkerApi: !("serviceWorker" in navigator) || location.protocol === "file:",
        readableStreamTransfer: supportsReadableStreamTransfer()
      }
    };
    this.startHandler = context.supports.serviceWorkerApi ? createFallbackStart(context) : createStartHandler(context);
    this.stopHandler = context.supports.serviceWorkerApi ? createFallbackStop(context) : createStop(context);
    return context;
  }
  async start(options = {}) {
    if (options.waitUntilReady === true) {
      devUtils10.warn('The "waitUntilReady" option has been deprecated. Please remove it from this "worker.start()" call. Follow the recommended Browser integration (https://mswjs.io/docs/integrations/browser) to eliminate any race conditions between the Service Worker registration and any requests made by your application on initial render.');
    }
    this.context.startOptions = mergeRight2(DEFAULT_START_OPTIONS, options);
    return await this.startHandler(this.context.startOptions, options);
  }
  stop() {
    super.dispose();
    this.context.events.removeAllListeners();
    this.context.emitter.removeAllListeners();
    this.stopHandler();
  }
};
function setupWorker(...handlers) {
  return new SetupWorkerApi(...handlers);
}
export { SetupWorkerApi, setupWorker };
