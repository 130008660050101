import { Routes } from '@angular/router';
import {authGuardFn} from "@auth0/auth0-angular";

export const routes: Routes = [
  {
    path: 'board/:id',
    title: '会議詳細',
    loadComponent: () => import('#features/boards/pages/board/board.component').then(m => m.BoardComponent),
    canActivate: [authGuardFn],
  },
  {
    path: 'spaces/:spaceId',
    title: 'スペース',
    loadChildren: () => import('#features/space/pages/space.routes').then(m => m.routes),
    canActivate: [authGuardFn],
  },
  {
    path: 'dashboard',
    title: 'ダッシュボード',
    loadChildren: () => import('#features/dashboard/pages/dashboard.routes').then(r => r.routes),
    canActivate: [authGuardFn],
  },
  {
    path: 'settings',
    title: '設定',
    loadChildren: () => import('#features/settings/pages/settings.routes').then(r => r.routes),
    canActivate: [authGuardFn],
  },
  {
    path: '',
    title: 'トップ',
    loadComponent: () => import('#features/top/top.component').then(m => m.TopComponent),
  },
];
