import {ApplicationConfig, importProvidersFrom} from '@angular/core';
import {provideRouter, withComponentInputBinding} from '@angular/router';

import {routes} from './app.routes';
import {provideAnimationsAsync} from '@angular/platform-browser/animations/async';
import {provideHttpClient, withInterceptors, withInterceptorsFromDi} from "@angular/common/http";
import {authHttpInterceptorFn, provideAuth0} from '@auth0/auth0-angular';
import {JwtModule} from "@auth0/angular-jwt";
import {AUDIENCE, CLIENT_ID, DOMAIN} from "../environments/auth0.conf";
import {httpInterceptor} from "#core/interceptor/http.interceptor";
import {MAT_DATE_LOCALE, provideNativeDateAdapter} from "@angular/material/core";

export const appConfig: ApplicationConfig = {
  providers: [
    provideRouter(routes, withComponentInputBinding()),
    provideAnimationsAsync(),
    provideNativeDateAdapter(),
    {provide: MAT_DATE_LOCALE, useValue: 'ja-JP'},
    provideHttpClient(
      withInterceptors([
        authHttpInterceptorFn,
        httpInterceptor,
      ]),
      withInterceptorsFromDi(),
    ),
    importProvidersFrom(
      JwtModule.forRoot({
        config: {
          tokenGetter: () => {
            return localStorage.getItem('access_token');
          },
        },
      })
    ),
    provideAuth0({
      domain: DOMAIN,
      clientId: CLIENT_ID,
      authorizationParams: {
        audience: AUDIENCE,
        redirect_uri: window.location.origin,
      }
    }),
  ]
};
